<template>
    <div>
        <el-dialog :visible.sync="show">
            <span slot="title">新增/修改 会议</span>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>会议主题</b></label>
                        <el-input type="text" placeholder="请输入主题" v-model="model.TITLE" />
                    </div>
                    <div class="form-group">
                        <label><b>主持人</b></label>
                        <el-input type="text" placeholder="请填写支持人" v-model="model.HOST" />
                    </div>
                    <div class="form-group">
                        <label><b>会议内容</b></label>
                        <el-input type="textarea" placeholder="会议主要内容" v-model="model.CONT" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>会议分类</b></label>
                        <el-select v-model="model.CAT" style="display: block" @change="$forceUpdate()">
                            <el-option v-for="(cat, index) in cat.dat" :value="cat" :label="cat" :key="index" />
                        </el-select>
                    </div>
                    <div class="form-group">
                        <label><b>会议地点</b></label>
                        <el-select v-model="model.LOC" style="display: block" @change="$forceUpdate()">
                            <el-option v-for="(item, index) in loc.dat" :value="item" :label="item" :key="index" />
                        </el-select>
                    </div>
                    <div class="form-group">
                        <label><b>开会时间</b></label>
                        <el-date-picker v-model="model.TIME" type="datetime" placeholder="选择日期时间"
                            format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
                            style="display: block; width: 100%;">
                        </el-date-picker>
                    </div>
                    <div class="form-group">
                        <label><b>与会人员</b></label>
                        <el-select v-model="model.JOINS" placeholder="请选择" multiple clearable style="display: block;">
                            <el-option v-for="(item, index) in meetPeo" :key="index" :label="item.NAME"
                                :value="item.ID + ''"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>图片</b></label>
                        <uploadImg ref="uploadImg" @getImgUrl="getImgUrl" :limit="6" :guid="model.GUID"
                            :imgURL="model.LST_PIC_URL">
                        </uploadImg>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button icon="el-icon-close" @click="show = false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import moment from "moment";
import uploadImg from "./uploadImg";
export default {
    components: { uploadImg },
    data() {
        return {
            show: false,
            model: {},

            cat: { dat: [] },
            loc: { dat: [] },

            meetPeo: []
        }
    },
    methods: {
        init(model) {
            this.show = true;
            if (model == undefined || model == null) {
                this.model = Object.assign({}, this.mi);
                this.model.LST_PIC_URL = []
                // this.$nextTick(() => {
                // this.$refs.uploadImg.imgUrl = []
                // console.log(this.$refs.uploadImg.imgUrl)
                // })
            }
            else {
                this.model = JSON.parse(JSON.stringify(model))
                console.log('14555', JSON.parse(JSON.stringify(model)).JOINS.split(','))
                this.model.TIME = moment(this.model.TIME).format('YYYY-MM-DD HH:mm')
                console.log(this.model.TIME)
                this.model.JOINS = JSON.parse(JSON.stringify(model)).JOINS.split(',')
                this.$nextTick(() => {
                    this.$refs.uploadImg.imgUrl = []
                    console.log(this.$refs.uploadImg.imgUrl)
                })
            }
            console.log(model)
            this.cat.dat = []
            this.loc.dat = []
            this.getType()
            this.getPeople()
        },
        getType() {
            let self = this;
            this.whale.remote.getCollection({
                url: '/api/School/FX/DictApi/GetList',
                data: {
                    TYPE: 'MEET'
                },
                completed(its) {
                    its.map(o => {
                        if (o.NAME == "MEET_CAT") self.cat.dat.push(o.VALUE)
                        else if (o.NAME == "MEET_LOC") self.loc.dat.push(o.VALUE)
                    })
                    if (!self.model.ID) {
                        console.log('dat', self.cat.dat)
                        self.model.CAT = self.cat.dat[0];
                        self.model.LOC = self.loc.dat[0];
                    }
                }
            })
        },
        getPeople() {
            let self = this;
            this.whale.remote.getCollection({
                url: '/api/School/ORG/TeacherApi/GetList',
                completed(its) {
                    console.log(its)
                    self.meetPeo = its
                }
            })
        },
        getImgUrl(val) {
            console.log(val)
        },

        submit() {
            console.log(this.model)
            if (this.model.TITLE == '') {
                this.$message.warning('请填写会议主题')
                return
            }
            if (this.model.HOST == '') {
                this.$message.warning('请填写会议主持人')
                return
            }
            if (this.model.CONT == '') {
                this.$message.warning('请填写会议内容')
                return
            }
            if (this.model.TIME == undefined) {
                this.$message.warning("请选择开会时间")
                return
            }
            if (this.model.JOINS.length == 0) {
                this.$message.warning('请选择会议人员')
            }
            this.model.CNT_JOIN = this.model.JOINS.length
            this.model.JOINS = this.model.JOINS.join(',');
            if (!this.model.ID) {
                this.model.PicBaseStrings = this.$refs.uploadImg.imgUrl.join(',')
            } else {
                this.model.PicBaseStrings = ''
            }
            console.log(this.model, this.$refs.uploadImg.imgUrl)
            let self = this;
            this.whale.remote.getResult({
                url: "/api/School/MEET/MinfoApi/Save",
                data: self.model,
                completed() {
                    self.show = false
                    self.$emit("on-saved");
                }
            })
        },

    },
}
</script>
<style lang="less" scoped></style>